import { useCallback, useMemo, useRef, useState } from "react";
import ActionButton from "../../components/ActionButton";
import TextField from "../../components/TextField";
import { getClassNames } from "../../utilities/props";

const FormSection = () => {
    const [showErrors, setShowErrors] = useState(false);
    const [showSent, setShowSent] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    const onSubmit = useCallback(() => {
        setShowSent(true);
        setShowErrors(false);
    }, []);

    const onInvalid = useCallback((event: React.FormEvent) => {
        setShowErrors(true);
        event.preventDefault();
        formRef.current!.querySelector<HTMLInputElement>(':invalid')?.focus();
    }, []);

    return (
        <section className="fx-column hp-sec-form" id="connect">

            {showSent && (
                <>
                    <p className="mt-2 fs-4 ff-alt ta-c text-gradient">
                        Thanks for being awesome!
                    </p>
                    <p className="__text mt-2 fs-1p25 ta-c">
                        Your message is on the way to us.
                        <br />We will get back in touch with you soon!
                        <br />Have a great day!
                    </p>

                    <div className="ta-c mt-3">
                        <ActionButton onClick={() => setShowSent(false)} >Send New Message</ActionButton>
                    </div>
                </>
            )}

            <div style={{ display: showSent ? 'none' : '' }}>
                <div className="fs-2 ff-title ta-c">
                    Want to talk about a project?
                </div>
                <div className="__text mt-2 ta-c fs-1p25 lh-p">
                    There’s nothing we love more than the infinite possibility of a brand new idea. Let’s frame something inspiring together
                </div>
                <p className="mt-2 fs-4 ff-alt ta-c text-gradient">
                    Let’s work together
                </p>

                <iframe id="contactIframe" name="contactIframe" style={{ display: 'none' }} />

                <form ref={formRef} className={getClassNames('__form mt-3 fx-column', [showErrors && '--show-errors'])}
                    method="post" action="/connect" target="contactIframe"
                    onInvalid={onInvalid} onSubmit={onSubmit}
                >
                    <div className="__form-row fx">
                        <TextField className="__form-field" label="Name" inputProps={{ name: 'name', required: true }} />
                        <TextField className="__form-field" label="Company" inputProps={{ name: 'company' }} />
                    </div>
                    <div className="__form-row fx">
                        <TextField className="__form-field" label="Email address" inputProps={{ name: 'email', required: true, type: 'email' }} />
                        <TextField className="__form-field" label="Phone number" inputProps={{ name: 'phone', required: true, type: 'tel' }} />
                    </div>
                    <div className="__form-row">
                        <TextField className="__form-field" label="What’s your idea?" textarea textareaProps={{ name: 'idea', required: true }} />
                    </div>
                    <div className="ta-c mt-3">
                        <ActionButton type="submit">Send Message</ActionButton>
                    </div>
                </form>
            </div>

        </section>
    )
}

export default FormSection;
import { testIsMobile } from "../specs";
import { AnimatedTextProps, AnimatedTextSplitBy } from "./AnimatedText";

export type AnimatedTextPropsPreset = Omit<AnimatedTextProps, 'children'>;

export const animatedTextLetersZoomInWithColor = (color: string, hlColor: string): AnimatedTextPropsPreset => {
    const isMobile = testIsMobile();

    if (isMobile) {
        return {
            from: { opacity: .2, scale: .9, color: hlColor },
            to: { opacity: 1, scale: 1, color },
            itemClassName: 'anim-h inline-block',
            scrollSync: true,
        }
    }

    return {
        from: { opacity: .2, z: -100 },
        to: { opacity: 1, z: 0, ease: 'back.out(1.4)', stagger: .05 },
        itemClassName: 'anim-h inline-block',
        split: AnimatedTextSplitBy.Letters,
        scrollSync: true,
        timeline: [
            {
                from: { color },
                to: { color: hlColor, stagger: .05, duration: .4 },
                pos: 0,
            },
            { to: { color, stagger: .05, duration: .2 }, pos: .4 },
        ],
    };
}

export const animatedTextZoomInWithColor = (color: string, hlColor: string): AnimatedTextPropsPreset => {
    const isMobile = testIsMobile();

    if (isMobile) {
        return {
            from: { opacity: .2, scale: .9, color: hlColor },
            to: { opacity: 1, scale: 1, color },
            itemClassName: 'inline-block',
            scrollSync: true,
        }
    }

    return {
        from: { opacity: .2, z: -100, color: hlColor },
        to: { opacity: 1, z: 0, color },
        itemClassName: 'inline-block',
        scrollSync: true,
    };
};

export const animatedTextParagraphFadeIn: AnimatedTextPropsPreset = {
    from: { opacity: 0, },
    to: { opacity: 1, stagger: .1 },
    itemClassName: 'to-botom',
    split: AnimatedTextSplitBy.Sentense,
    scrollSync: true,
}

export const animatedTextFadeInNoSplit = {
    from: { opacity: 0, },
    to: { opacity: 1 },
    scrollSync: true,
}
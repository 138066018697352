import { useCallback, useRef, useState } from "react";
import { PropsWithClassName, getClassNameFromProps, getClassNames } from "../utilities/props";

interface TextFieldProps extends PropsWithClassName {
    label: string;
    textarea?: boolean;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    textareaProps?: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
}

const TextField = (props: TextFieldProps) => {
    const [hasValue, setHasValue] = useState(false);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setHasValue(!!event.target.value);
    }, []);

    const isRequired = props.textarea ? props.textareaProps?.required : props.inputProps?.required;

    return (
        <div className={getClassNameFromProps('txf', props)}>
            {props.textarea && (
                <textarea
                    className={getClassNames('txf-input', [hasValue && '--hasValue'])} onChange={onChange}
                    {...props.textareaProps}
                />
            )}
            {!props.textarea && (
                <input
                    className={getClassNames('txf-input', [hasValue && '--hasValue'])} onChange={onChange}
                    {...props.inputProps}
                />
            )}

            <label className="txf-label">{props.label} {isRequired && '*'}</label>

            <p className="txf-error">{props.label} is required</p>
        </div>
    )
}

export default TextField;
export interface PropsWithClassName {
    className?: string;
}

export const getClassNameFromProps = (defaultClassName: string, props: PropsWithClassName) => {
    if (props.className) return defaultClassName + ' ' + props.className;
    return defaultClassName;
}

export const getClassNames = (defaultClassName: string, items: (string | undefined | false)[]) => {
    let classes = [defaultClassName];

    for (const item of items) {
        if (!item) continue;
        classes.push(item);
    }

    return classes.join(' ');
}
import { useCallback, useEffect, useRef, useState } from "react";
import AnimatedDevice, { AnimatedDeviceHandle, AnimatedDeviceProps } from "../../components/AnimatedDevice";
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import { PropsWithClassName, getClassNameFromProps } from "../../utilities/props";
import { ANIM_SPECS, testIsSmallBreakpoint } from "../../specs";
import AnimatedText from "../../components/AnimatedText";
import { animatedTextParagraphFadeIn, animatedTextLetersZoomInWithColor } from "../../components/animatedTextPresets";

interface ShowcaseSectionDevice {
    pos: [number, number];
    posSmall: [number, number];
    scale: number;
    scaleSmall: number;

    elevation?: number;
    animProps: Omit<AnimatedDeviceProps, 'scale'>;
}

interface ShowcaseSectionProps extends PropsWithClassName {
    heading: string;
    description: string;
    color: string;
    hlColor: string;
    canvasBgSrc?: string;
    isLaptop?: boolean;
    canvasSize: [number, number];
    canvasSizeSmall: [number, number];
    devices: ShowcaseSectionDevice[],
}

const ShowcaseSection = (props: ShowcaseSectionProps) => {
    const elRef = useRef<HTMLSelectElement>(null);
    const elPlaceholderRef = useRef<HTMLCanvasElement>(null);
    const elContentRef = useRef<HTMLDivElement>(null);
    const deviceRefs = useRef<HTMLDivElement[]>([]);
    const animDeviceRefs = useRef<AnimatedDeviceHandle[]>([]);

    const [isSmall, setIsSmall] = useState(testIsSmallBreakpoint());
    // const isMobile = testIsMobile();

    const onResize = useCallback(() => {
        let scale = 1;
        const maxWidth = 928;
        const availableWidth = elPlaceholderRef.current!.offsetWidth;

        setIsSmall(testIsSmallBreakpoint());

        if (availableWidth < maxWidth) scale = availableWidth / maxWidth;

        elContentRef.current!.style.transform = `scale(${scale.toFixed(2)})`;
    }, []);

    const toggleDeviceAnim = useCallback((pause = false) => {
        for (let i = 0; i < animDeviceRefs.current.length; i++) {
            if (pause) animDeviceRefs.current[i].pause();
            else animDeviceRefs.current[i].play();
        }
    }, [])

    useEffect(() => {
        gsap.context(() => {
            // if (isMobile) return;

            const tl = gsap.timeline({ defaults: { ease: 'none' } });

            for (let i = 0; i < props.devices.length; i++) {
                // const shadowEl = deviceRefs.current[i].querySelector('.adv-shadow') as HTMLElement;

                if (props.isLaptop) {
                    tl
                        .fromTo(deviceRefs.current[i], { rotateX: -60 }, { rotateX: 0 }, 0)
                        .to(deviceRefs.current[i], { rotateX: 0 }, 1)
                    // .fromTo(shadowEl, { opacity: -.6 }, { opacity: .5 }, 0)
                    continue;
                }

                const elevation = props.devices[i].elevation ?? i;

                // gsap.set(shadowEl, { scale: .96 + (elevation * .02), opacity: .6 - elevation * .1 })

                tl
                    .fromTo(deviceRefs.current[i], { rotateX: 0 * elevation, translateY: 150 * elevation }, { rotateX: 0, translateY: 0 }, 0)
                    .to(deviceRefs.current[i], { translateY: -150 * elevation }, 1)
            }

            ScrollTrigger.create({
                trigger: elPlaceholderRef.current!,
                animation: tl,
                scrub: ANIM_SPECS.scrup,
                onEnter: () => toggleDeviceAnim(),
                onEnterBack: () => toggleDeviceAnim(),
                onLeave: () => toggleDeviceAnim(true),
                onLeaveBack: () => toggleDeviceAnim(true),
            })
        }, elRef.current!);

        // {
        //     const letters = Array.from(elRef.current!.querySelectorAll<HTMLElement>('.anim-h')!);
        //     for (const letter of letters) {
        //         // const anim = gsap.to(letter, { z: -25, paused: true, ease: "power4.out", duration: .5, color: props.hlColor, opacity: .5 })
        //         const anim = gsap.fromTo(letter, { color: props.color }, { color: props.hlColor, duration: .2 })
        //         letter.addEventListener('mouseenter', (event) => {
        //             // SoundPlayer.instance.play('key3');
        //             anim.play();
        //         });
        //         letter.addEventListener('mouseleave', (event) => anim.reverse());
        //     }
        // }

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    const renderDevice = useCallback((device: ShowcaseSectionDevice, index: number) => {
        // if (isMobile) return null;
        const left = isSmall ? device.posSmall[0] : device.pos[0];
        const top = isSmall ? device.posSmall[1] : device.pos[1];
        const scale = isSmall ? device.scaleSmall : device.scale;
        return (
            <div key={index}
                ref={ref => deviceRefs.current[index] = ref!}
                className="absolute will-transform"
                style={{ left, top }}>
                <AnimatedDevice ref={ref => animDeviceRefs.current[index] = ref!} scale={scale} {...device.animProps} />
            </div>
        )
    }, [isSmall])

    const canvasSize = isSmall ? props.canvasSizeSmall : props.canvasSize;

    return (
        <section ref={elRef} className={getClassNameFromProps('hp-sec-showcase fx-column ai-center of-hidden', props)}>
            <div className="__title fx-column ai-center">
                <AnimatedText className="ff-title fs-6 px-text"{...animatedTextLetersZoomInWithColor(props.color, props.hlColor)} >{props.heading}</AnimatedText>
                <AnimatedText className="fs-1p5 ta-c mt-2 p text-secondary" {...animatedTextParagraphFadeIn}>{props.description}</AnimatedText>
            </div>
            <div className="__canvas">
                <canvas ref={elPlaceholderRef} className="__placeholder" width={canvasSize[0]} height={canvasSize[1]} style={{ backgroundImage: props.canvasBgSrc ? `url(${props.canvasBgSrc})` : undefined }} />
                <div ref={elContentRef} className="__content px-device">
                    {props.devices.map(renderDevice)}
                </div>
            </div>
        </section>
    );
}

export default ShowcaseSection;
import { gsap } from "gsap";
import { useCallback, useEffect, useState } from "react";
import { getClassNames } from "../../utilities/props";
import { LINKS, testIsNavBreakpoint } from "../../specs";
import Link from "../../components/Link";
import { SpeakerHigh, SpeakerX } from "@phosphor-icons/react/dist/ssr";
import AudioPlayer from "../../components/AudioPlayer";

const Nav = () => {
    const [show, setShow] = useState(false);
    const showNavDrawer = testIsNavBreakpoint();

    useEffect(() => {
        return;
        gsap.context(() => {
            gsap.fromTo(['.hp-nav', '.hp-nav-trigger', '.hp-nav-title'],
                { opacity: 0, yPercent: -100 },
                { opacity: 1, yPercent: 0, duration: 1, delay: 2.5, clearProps: 'all' })
            gsap.fromTo('.hp-nav a',
                { opacity: 0, yPercent: -100 },
                { opacity: 1, yPercent: 0, duration: .75, stagger: .2, delay: 3, clearProps: 'all' });
        });
    }, []);

    useEffect(() => {
        (document.scrollingElement as HTMLElement).style.overflow = show ? 'hidden' : '';
        document.documentElement.style.overflow = show ? 'hidden' : '';
    }, [show]);

    const toggle = useCallback(() => {
        setShow(show => !show);
    }, []);

    const onLinkClick = showNavDrawer ? toggle : undefined;

    return (
        <>
            {showNavDrawer && (
                <div className={getClassNames('hp-nav-backdrop full-size-vh', [show && '--reveal'])} />
            )}

            <nav className={getClassNames('hp-nav fx ai-center jc-end', [show && '--reveal'])}>
                <div className="__links">
                    <VolumeButton />
                    <Link href="#about" onClick={onLinkClick}>About</Link>
                    <Link href="#services" onClick={onLinkClick}>Services</Link>
                    <Link href="#culture" onClick={onLinkClick}>Culture</Link>
                    <Link href="#connect" onClick={onLinkClick}>Connect</Link>
                </div>
                <div className="__links2 fs-sm">
                    <Link href={LINKS.dribble} target="_blank" onClick={onLinkClick}>Dribble</Link>
                    <Link href={LINKS.instagram} target="_blank" onClick={onLinkClick}>Instagram</Link>
                    <Link href={LINKS.linkedIn} target="_blank" onClick={onLinkClick}>LinkedIn</Link>
                </div>
                <p className="fs-1p5">&nbsp;</p>
            </nav>

            <Link href={LINKS.site} className="hp-nav-title ff-title fs-1p5">aframe.studio</Link>

            <nav className="hp-nav-trigger" onClick={toggle}>
                <div className={getClassNames('hp-nav-menuIcon', [show && '--close'])}>
                    <div className="__line"></div>
                    <div className="__line"></div>
                </div>
            </nav>
        </>
    )
}

export default Nav;

const VolumeButton = () => {
    const [isPlaying, setPlaying] = useState(AudioPlayer.instance.isPlaying());

    let className = "hp-nav-volume togglable";
    if (!isPlaying) className += " --toggle";

    useEffect(() => {
        AudioPlayer.instance.onChange(setPlaying);
    }, [])

    const toggle = () => {
        AudioPlayer.instance.isPlaying() ? AudioPlayer.instance.pause() : AudioPlayer.instance.play();
    }

    return (
        <button className={className} onClick={toggle}>
            <SpeakerHigh className="absolute __item1" size={20} />
            <SpeakerX className="absolute __item2" size={20} />
            &nbsp;
            &nbsp;
        </button>
    )
}


'use client'

export interface Sound {
    key: string;
    src: string;
    volume?: number;
}
export interface SoundsSet {
    key: string;
    srcs: string[];
    volume?: number;
}

export default class SoundPlayer {
    private static _instance?: SoundPlayer;
    static get instance() {
        if (!this._instance) this._instance = new SoundPlayer();
        return this._instance;
    }

    private _sounds: {
        [key: string]: {
            els: HTMLAudioElement[],
            next: number,
        }
    } = {};

    add(...sounds: (Sound | SoundsSet)[]) {
        for (const sound of sounds) {
            if ((sound as SoundsSet).srcs) {
                this._sounds[sound.key] = { els: [], next: 0 }
                for (const url of (sound as SoundsSet).srcs) {
                    const el = new Audio(url);
                    this._sounds[sound.key].els.push(el);
                    el.volume = sound.volume ?? 1;
                }
            } else {

            }
        }
    }

    play(key: string) {
        const sounds = this._sounds[key];
        if (!sounds) return;
        sounds.els[sounds.next].play()
            .catch(() => {
                console.log('Audio failed!');
            })
        sounds.next = (sounds.next + 1) % sounds.els.length;
    }
}

export const playSoundWithTimeout = (key: string, delay = 100) => {
    let timeout: number;

    return {
        onMouseEnter: () => {
            timeout = window.setTimeout(() => {
                SoundPlayer.instance.play(key)
            }, delay);
        },
        onMouseLeave: () => {
            window.clearTimeout(timeout);
        }
    }
}
'use client'

class AudioPlayer {
    private static _instance?: AudioPlayer;
    private _currentIndex = 0;

    private el: HTMLAudioElement;
    private _analyser?: AnalyserNode;
    private tracks: string[] = [];
    public fftSize = 1024;
    private _changeListener?: (isPlaying: boolean) => void;

    constructor() {
        this.el = new Audio();
        this.el.addEventListener('ended', () => this.next());
        this.el.volume = .05;
        this.el.addEventListener('playing', this.onChangeHandler)
        this.el.addEventListener('pause', this.onChangeHandler)
    }

    private createAnalyser() {
        if (this._analyser) return;

        const audioCtx = new AudioContext();
        const source = audioCtx.createMediaElementSource(this.el);

        this._analyser = audioCtx.createAnalyser();

        source.connect(this._analyser);
        this._analyser.connect(audioCtx.destination);

        this._analyser.fftSize = this.fftSize;

        this.el.dispatchEvent(new Event('analysercreated'));
    }

    private onChangeHandler = () => {
        this._changeListener?.(this.isPlaying());
    }

    onChange(changeListener: (isPlaying: boolean) => void) {
        this._changeListener = changeListener;
    }

    setTracks(tracks: string[]) {
        this.tracks = tracks;
        this.el.src = tracks[0];
        this._currentIndex = 0;
    }

    play() {
        return this.el.play().then(() => this.createAnalyser())
    }

    pause() {
        return this.el.pause();
    }

    next(prev?: boolean) {
        this._currentIndex = (this.tracks.length + this._currentIndex + (prev ? -1 : 1)) % this.tracks.length;

        this.el.pause();
        this.el.src = this.tracks[this._currentIndex];
        this.el.play();

        return this._currentIndex;
    }

    prev() {
        return this.next(true);
    }

    whenPlaying() {
        if (!this.el.paused && this.analyser) {
            return Promise.resolve();
        }
        return new Promise<void>(resolve => {
            this.el.addEventListener('analysercreated', () => {
                resolve();
            }, { once: true })
        });
    }

    isPlaying = () => !this.el.paused;

    get analyser() {
        return this._analyser;
    }

    get currentIndex() {
        return this._currentIndex;
    }

    static get instance() {
        if (!this._instance) this._instance = new AudioPlayer();
        return this._instance;
    }
}

export default AudioPlayer;
import gsap from "gsap";
import { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef } from "react";
import { PropsWithClassName } from "../utilities/props";
import throttleFps from "../utilities/throttleFps";
import Noise from "../utilities/Noise";
import ColorGradient from "../utilities/ColorGradient";
import { hexToRgb } from "../utilities/helpers";
import useCallbackRef from "../utilities/useCallbackRef";
import { testIsMobile } from "../specs";

export interface WavesHandle {
    play: () => void;
    pause: () => void;
}

const SPECS_Mobile = {
    lineWidth: 14,
    frequency: 4,
    lineCount: 6,
    waveHeight: .6,
}

const SPECS = {
    frameInterval: 1000 / 60,
    lineCount: 8,
    lineWidth: 24,
    lineOpacity: .4,

    noiseWidth: 5,
    waveHeight: 1,
    noiseIncrement: 0.007,
    frequency: 2.5,

    decay: 0.98,

    lineGradient: new ColorGradient([
        hexToRgb('FFA037'),
        hexToRgb('EF5A98'),
        hexToRgb('7E84FC'),
    ]),
}

interface Line {
    color: string;
    offset: number;
}

const LINES: Line[] = Array(SPECS.lineCount).fill(undefined).map((line, index) => {
    const iPercent = index / SPECS.lineCount;
    return {
        color: `rgba(${SPECS.lineGradient.get(iPercent).join(',')},${(SPECS.lineOpacity + iPercent / 2).toFixed(2)})`,
        offset: iPercent * SPECS.noiseWidth,
    }
})

const Waves = forwardRef<WavesHandle, { isMobile: boolean } & PropsWithClassName>((props, ref) => {
    const canvas = useRef<HTMLCanvasElement>(null);
    const context = useRef<CanvasRenderingContext2D>();
    const noise = useMemo(() => new Noise(), []);
    const variables = useRef({
        width: 0,
        height: 0,
        halfWidth: 0,
        halfHeight: 0,
        noisePos: 0,
    });
    const motionVars = useRef({
        mouseXPercent: 0,
        mouseYPercent: 0,
        movement: 0,
        height: 0,
    })

    const resize = () => {

        const width = canvas.current!.offsetWidth;
        const height = canvas.current!.offsetHeight;

        const pixelRatio = testIsMobile() ? 1 : window.devicePixelRatio;

        variables.current!.width = width;
        variables.current!.height = height;
        variables.current!.halfWidth = width / 2;
        variables.current!.halfHeight = height / 2;

        canvas.current!.width = width * pixelRatio;
        canvas.current!.height = height * pixelRatio;
        context.current!.scale(pixelRatio, pixelRatio);

        setup();
    }
    const resizeCallback = useCallbackRef(resize);

    useLayoutEffect(() => {
        context.current = canvas.current!.getContext('2d')!;
        resize();
    }, [])

    const setup = () => {
        const ctx = context.current!;
        ctx.lineWidth = SPECS.lineWidth;
        ctx.globalCompositeOperation = 'lighter';

        // Mobile
        if (props.isMobile) Object.assign(SPECS, SPECS_Mobile);
    }

    const render = throttleFps(() => {
        const ctx = context.current!;
        const vars = variables.current!;

        motionVars.current.movement = motionVars.current.movement > .9 ? motionVars.current.movement * .95 : 0;
        motionVars.current.height = motionVars.current.height > .9 ? motionVars.current.height * .95 : 0;

        ctx.clearRect(0, 0, variables.current.width!, variables.current.height!);

        const freqMultiplier = 1 + (1 - Math.abs(motionVars.current.mouseXPercent - 1)) * 1.5;
        const speedMultiplier = 1 + Math.min(motionVars.current.movement / 100, 50);
        const ixInvert = motionVars.current.height / 25;
        const yDistance = (motionVars.current!.mouseYPercent * 2 - 1) * 2;

        ctx.lineWidth = SPECS.lineWidth * (1 + Math.min(motionVars.current.movement / 500, 1));

        let noiseAmount = 0;

        const yOffset = props.isMobile ? vars.height * .25 : 0;

        for (let i = 0; i < LINES.length; i++) {
            ctx.strokeStyle = LINES[i].color;

            ctx.beginPath();
            for (let ix = 0; ix < vars.halfWidth; ix++) {
                const xPercent = ix / vars.halfWidth;
                const xEase = Math.pow(xPercent, 3) * (1 + ixInvert);

                noiseAmount = noise.get(
                    vars.noisePos + xPercent / 2 * SPECS.frequency * freqMultiplier,
                    vars.noisePos + LINES[i].offset,
                    0,
                )

                const x = vars.halfWidth - ix;
                const y = vars.halfHeight + (noiseAmount * vars.halfHeight * xEase * SPECS.waveHeight) + yDistance * i + yOffset;

                if (x === 0) ctx.moveTo(x, y)
                else ctx.lineTo(x, y);
            }
            ctx.stroke();

            ctx.beginPath();
            for (let ix = 0; ix < vars.halfWidth; ix++) {
                const xPercent = ix / vars.halfWidth;
                const xEase = Math.pow(xPercent, 3) * (1 + ixInvert);

                noiseAmount = noise.get(
                    vars.noisePos + (.5 + xPercent / 2) * SPECS.frequency * freqMultiplier,
                    vars.noisePos + LINES[i].offset,
                    0,
                )

                const x = vars.halfWidth + ix;
                const y = vars.halfHeight + (noiseAmount * vars.halfHeight * xEase * SPECS.waveHeight) + yDistance * i + yOffset;

                if (x === 0) ctx.moveTo(x, y)
                else ctx.lineTo(x, y);
            }
            ctx.stroke();

        }

        vars.noisePos -= SPECS.noiseIncrement * speedMultiplier;

    }, SPECS.frameInterval)

    const renderCallback = useCallbackRef(render);

    const onMouseMove = (event: MouseEvent) => {
        motionVars.current.mouseXPercent = event.clientX / window.innerWidth;
        motionVars.current.mouseYPercent = event.clientY / window.innerHeight;
        motionVars.current.movement += Math.abs(event.movementX) + Math.abs(event.movementY);
    }

    const onMouseMoveCallback = useCallbackRef(onMouseMove)

    const onScroll = (event: Event) => {
        motionVars.current.movement += 20;
    }

    const onScrollCallback = useCallbackRef(onScroll)

    const onMouseDown = () => {
        motionVars.current.height = 100;
        variables.current!.noisePos += .5;
    }

    const onMouseDownCallback = useCallbackRef(onMouseDown)

    useEffect(() => {
        window.addEventListener('resize', resizeCallback);
        window.addEventListener('mousemove', onMouseMoveCallback);
        window.addEventListener('scroll', onScrollCallback);
        window.addEventListener('mousedown', onMouseDownCallback);

        setup();

        const clipPathObj = { value: 0 }

        gsap.to(clipPathObj, {
            value: 75, duration: 2,
            onUpdate: () => { canvas.current!.style.clipPath = `circle(${(clipPathObj.value).toFixed(2)}%)` },
        })
            .delay(2)
            .then(() => canvas.current!.style.clipPath = '');

        return () => {
            window.removeEventListener('resize', resizeCallback);
            window.removeEventListener('mousemove', onMouseMoveCallback);
            window.removeEventListener('scroll', onScrollCallback);
            window.removeEventListener('mousedown', onMouseDownCallback);
        }
    }, [])

    useImperativeHandle(ref, () => ({
        pause: () => {
            gsap.ticker.remove(renderCallback)
        },
        play: () => {
            gsap.ticker.add(renderCallback)
        },
    }))

    return (
        <canvas ref={canvas}
            className={props.className}
            style={{ clipPath: 'circle(0%)' }}
        />
    )
});

export default Waves;
import { CSSProperties, forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { gsap } from "gsap";
import { PropsWithClassName, getClassNames } from "../utilities/props";


interface DeviceSpec {
    width: number,
    height: number,
    frameMargin: number | [number, number, number, number],
    screenBorderRadius: number | [number, number, number, number],
    imageBorderRadius: number,
}

const DEVICE_SPECS: { [key: string]: DeviceSpec } = {
    phone: {
        width: 423,
        height: 860,
        frameMargin: 24,
        screenBorderRadius: 50,
        imageBorderRadius: 50,
    },
    tablet: {
        width: 1414,
        height: 1072,
        frameMargin: 24,
        screenBorderRadius: 50,
        imageBorderRadius: 50,
    },
    laptop: {
        width: 1640,
        height: 940,
        frameMargin: [20, 137, 66, 137],
        screenBorderRadius: [36, 36, 0, 0],
        imageBorderRadius: 8,
    }
}

export enum AnimatedDeviceTypes {
    Phone,
    Tablet,
    Laptop,
}

export enum AnimatedDeviceTransitions {
    AppSwitch,
    FadeIn,
    FadeInScrollOut,
    SlideInBottom,
    SlideInLeft,
    SlideInRight,
}

export interface AnimatedDeviceProps extends PropsWithClassName {
    type: AnimatedDeviceTypes;
    screens: { src: string, transition: AnimatedDeviceTransitions, scrollable?: boolean }[];
    scale: number;
}

export interface AnimatedDeviceHandle {
    play: () => void;
    pause: () => void;
}

const AnimatedDevice = forwardRef<AnimatedDeviceHandle, AnimatedDeviceProps>((props, ref) => {
    const elRef = useRef(null);
    const anim = useRef<gsap.core.Timeline>();
    const screenElsRef = useRef<HTMLDivElement[]>([]);

    useImperativeHandle(ref, () => ({
        play: () => anim.current?.play(),
        pause: () => anim.current?.pause(),
    }), []);

    useEffect(() => {
        gsap.context(() => {

            const setStyles = (p: number, n: number, nVars?: gsap.TweenVars, pVars?: gsap.TweenVars) => {
                gsap.set(screenElsRef.current[n], { zIndex: 2, opacity: 1, xPercent: 0, yPercent: 0, scale: 1, ...nVars });
                gsap.set(screenElsRef.current[p], { zIndex: 1, opacity: 1, xPercent: 0, yPercent: 0, scale: 1, ...pVars });
            }

            const clesrStyles = (p: number) => {
                gsap.set(screenElsRef.current[p], { opacity: 0 });
            }

            // Setup initial states
            for (let i = 0; i < props.screens.length; i++) {
                gsap.set(screenElsRef.current[i], { opacity: 0 });
            }

            const wait = 1;

            anim.current = gsap.timeline();

            for (let n = 0; n < props.screens.length; n++) {
                const p = (props.screens.length + n - 1) % props.screens.length;

                switch (props.screens[n].transition) {
                    case AnimatedDeviceTransitions.AppSwitch:
                        anim.current.add(
                            gsap.timeline()
                                .call(() => setStyles(p, n, { xPercent: -100, scale: .8 }))

                                .to(screenElsRef.current[p], { scale: .8 }, 0)
                                .to(screenElsRef.current[p], { xPercent: 100 }, 1)
                                .to(screenElsRef.current[n], { xPercent: 0 }, 1)
                                .to(screenElsRef.current[n], { scale: 1 }, 2)

                                .call(() => clesrStyles(p), undefined, 3 + wait)
                        )
                        break;
                    case AnimatedDeviceTransitions.FadeIn:
                        anim.current.add(
                            gsap.timeline()
                                .call(() => setStyles(p, n, { opacity: 0 }))

                                .to(screenElsRef.current[n], { opacity: 1, duration: .75 })

                                .call(() => clesrStyles(p), undefined, .75 + wait)
                        )
                        break;
                    case AnimatedDeviceTransitions.FadeInScrollOut:
                        anim.current.add(
                            gsap.timeline()
                                .call(() => setStyles(p, n, { opacity: 0 }))

                                .to(screenElsRef.current[p], { backgroundPositionY: '100%', duration: 2 }, 1)
                                .to(screenElsRef.current[n], { opacity: 1, duration: .5 })

                                .call(() => clesrStyles(p), undefined, 3.5 + wait)
                        )
                        break;
                    case AnimatedDeviceTransitions.SlideInBottom:
                        anim.current.add(
                            gsap.timeline()
                                .call(() => setStyles(p, n, { yPercent: 100 }))

                                .to(screenElsRef.current[p], { opacity: 0, scale: .8 }, 0)
                                .to(screenElsRef.current[n], { yPercent: 0 }, 0)

                                .call(() => clesrStyles(p), undefined, 1 + wait)
                        )
                        break;
                    case AnimatedDeviceTransitions.SlideInLeft:
                        anim.current.add(
                            gsap.timeline()
                                .call(() => setStyles(p, n, { xPercent: -100 }))

                                .to(screenElsRef.current[p], { opacity: 0, scale: .9 }, 0)
                                .to(screenElsRef.current[n], { xPercent: 0 }, 0)

                                .call(() => clesrStyles(p), undefined, 1 + wait)
                        )
                        break;
                    default:
                        anim.current.add(
                            gsap.timeline()
                                .call(() => setStyles(p, n, { xPercent: 100 }))

                                .to(screenElsRef.current[p], { opacity: 0, scale: .9 }, 0)
                                .to(screenElsRef.current[n], { xPercent: 0 }, 0)

                                .call(() => clesrStyles(p), undefined, 1 + wait)
                        )
                        break;
                }
            }

            anim.current.repeat(-1)

        }, elRef.current!);
    }, []);

    let specs: DeviceSpec = DEVICE_SPECS.phone;
    let deviceString = 'phone';
    switch (props.type) {
        case AnimatedDeviceTypes.Tablet:
            specs = DEVICE_SPECS.tablet;
            deviceString = 'tablet';
            break;
        case AnimatedDeviceTypes.Laptop:
            specs = DEVICE_SPECS.laptop;
            deviceString = 'laptop';
            break;
    }

    const frameMargin = Array.isArray(specs.frameMargin) ? specs.frameMargin : Array(4).fill(specs.frameMargin);
    const screenBorderRadius = Array.isArray(specs.screenBorderRadius) ? specs.screenBorderRadius : Array(4).fill(specs.screenBorderRadius);
    const screenStyles: CSSProperties = {
        top: frameMargin[0] * props.scale,
        right: frameMargin[1] * props.scale,
        bottom: frameMargin[2] * props.scale,
        left: frameMargin[3] * props.scale,
        borderTopLeftRadius: screenBorderRadius[0] * props.scale,
        borderTopRightRadius: screenBorderRadius[1] * props.scale,
        borderBottomRightRadius: screenBorderRadius[2] * props.scale,
        borderBottomLeftRadius: screenBorderRadius[3] * props.scale,
    };
    const imageStyles: CSSProperties = { borderRadius: specs.imageBorderRadius * props.scale };

    return (
        <div className="adv" style={{ width: specs.width * props.scale, height: specs.height * props.scale }}>
            <div className="adv-frame" style={{
                backgroundImage: `url(./assets/${deviceString}-frame.svg)`,
            }} />
            <div className="adv-screen" style={screenStyles}>
                {props.screens.map((screen, index) => (
                    <div
                        key={index}
                        ref={ref => screenElsRef.current[index] = ref!}
                        className="adv-image will-transform will-fade"
                        style={{
                            ...imageStyles,
                            backgroundImage: `url(${screen.src})`,
                        }}
                    />
                ))}
            </div>
        </div>
    )
});

export default AnimatedDevice;
import SoundPlayer from "./SoundPlayer";

const Link = (props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
    return <a {...props}
        onMouseEnter={() => SoundPlayer.instance.play('hover')}
        onClick={(event) => {
            SoundPlayer.instance.play('click');
            props.onClick?.(event);
        }}
    >{props.children}</a>
}

export default Link;
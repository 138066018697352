import { useCallback, useEffect, useRef } from "react";
import { PropsWithClassName, getClassNames } from "../utilities/props";
import ScrollTrigger from 'gsap/ScrollTrigger';
import { gsap } from "gsap";
import { ANIM_SPECS } from "../specs";

export enum AnimatedTextSplitBy {
    Letters,
    Words,
    Sentense,
}

export interface AnimatedTextProps extends PropsWithClassName {
    children: string;
    itemClassName?: string;
    split?: AnimatedTextSplitBy;
    from: gsap.TweenVars;
    to: gsap.TweenVars;
    scrollSync?: boolean | { start?: string; end?: string; };
    timeline?: { from?: gsap.TweenVars | undefined; to: gsap.TweenVars; pos?: gsap.Position; }[];
    markers?: boolean;
    render?: (content: string, index: number) => JSX.Element;
}

const AnimatedText = (props: AnimatedTextProps) => {
    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        gsap.context(() => {
            let animation: gsap.core.Tween | gsap.core.Timeline;

            if (props.timeline) {
                animation = gsap.timeline().fromTo('span', props.from, props.to);

                for (const tween of props.timeline) {
                    if (tween.from) animation.fromTo('span', tween.from, tween.to, tween.pos);
                    else animation.to('span', tween.to, tween.pos);
                }
            } else {
                animation = gsap.fromTo('span', props.from, props.to);
            }

            if (props.scrollSync) {
                let start = 'top 75%';
                let end = 'top 25%';
                if (typeof props.scrollSync === 'object') {
                    if (props.scrollSync.start) start = props.scrollSync.start;
                    if (props.scrollSync.end) end = props.scrollSync.end;
                }

                ScrollTrigger.create({
                    trigger: elRef.current,
                    animation,
                    start,
                    end,
                    scrub: ANIM_SPECS.scrup,
                    markers: props.markers,
                });
            }
        }, elRef.current!)
    }, []);

    let splitBy: string | undefined = undefined;
    switch (props.split) {
        case AnimatedTextSplitBy.Letters:
            splitBy = '';
            break;
        case AnimatedTextSplitBy.Words:
            splitBy = ' ';
            break;
        case AnimatedTextSplitBy.Sentense:
            splitBy = '. ';
            break;
    }

    const items = splitBy === undefined ? [props.children] : props.children.split(splitBy);

    const renderItem = useCallback((item: string, index: number) => {
        let content: string | JSX.Element = splitBy ? item + splitBy : item;
        if (props.render) content = props.render(content, index);

        return (
            <span key={index} className={getClassNames('atx-item will-transform', [props.itemClassName])}>
                {content}
            </span>
        )
    }, [])

    return (
        <div ref={elRef} className={props.className}>
            {items.map(renderItem)}
        </div>
    )
}

export default AnimatedText;

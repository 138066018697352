import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import AnimatedText, { AnimatedTextSplitBy } from "../../components/AnimatedText";
import { WaveAnimationHandle } from "../../components/WaveAnimation";
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import ActionButton from "../../components/ActionButton";
import { testIsMobile, testIsSmallBreakpoint } from "../../specs";
import { AnimatedTextPropsPreset } from "../../components/animatedTextPresets";
import { getClassNames } from "../../utilities/props";
import Waves from "../../components/Waves";
import SoundPlayer from "../../components/SoundPlayer";
import { AccentHlColor } from "./colors";

const LandingSection = () => {
    const elRef = useRef(null);
    const waveAnimation = useRef<WaveAnimationHandle>(null);
    const experiencesItemRefs = useRef<HTMLElement[]>([])
    const isSmall = testIsSmallBreakpoint();
    const isMobile = testIsMobile();

    useEffect(() => {
        gsap.context(() => {
            ScrollTrigger.create({
                trigger: elRef.current!,
                onEnter: waveAnimation.current!.play,
                onEnterBack: waveAnimation.current!.play,
                onLeave: waveAnimation.current!.pause,
                onLeaveBack: waveAnimation.current!.pause,
            });

            gsap.fromTo('.hp-landing-connectAction', { opacity: 0 }, { opacity: 1, duration: 1, delay: 3 });
            gsap.fromTo('.hp-landing-scrollLine', { opacity: 0 }, { opacity: 1, duration: 1, delay: 3.25 });
        }, elRef.current!);

        const timeout = setTimeout(() => {
            {
                const letters = Array.from(document.querySelectorAll<HTMLElement>('.anim-l1')!);
                for (const letter of letters) {
                    const anim = gsap.to(letter, { yPercent: -25, rotateX: 25, paused: true, ease: "power4.out", duration: .5, color: AccentHlColor })
                    letter.addEventListener('mouseenter', (event) => {
                        SoundPlayer.instance.play('key3');
                        anim.play();
                    });
                    letter.addEventListener('mouseleave', (event) => anim.reverse());
                }
            }
            {
                const letters = Array.from(document.querySelectorAll<HTMLElement>('.anim-l2')!);
                for (const letter of letters) {
                    const anim = gsap.to(letter, { yPercent: -25, rotateX: 25, paused: true, ease: "power4.out", duration: .5, color: AccentHlColor })
                    letter.addEventListener('mouseenter', (event) => {
                        SoundPlayer.instance.play('key4');
                        anim.play();
                    });
                    letter.addEventListener('mouseleave', (event) => anim.reverse());
                }
            }
            {
                const letters = Array.from(document.querySelectorAll<HTMLElement>('.anim-l3')!);
                for (const letter of letters) {
                    const anim = gsap.to(letter, { z: 40, paused: true, ease: "power4.out", duration: .5, filter: "saturate(2)" })
                    letter.addEventListener('mouseenter', (event) => {
                        SoundPlayer.instance.play('key5');
                        anim.play();
                    });
                    letter.addEventListener('mouseleave', (event) => anim.reverse());
                }
            }

        }, 1000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    const onResize = useCallback(() => {
        if (isMobile) return;

        // Fix Gradient
        const parentEl = experiencesItemRefs.current[0].parentElement!.parentElement!;
        const width = parentEl.offsetWidth;
        const height = parentEl.offsetHeight;
        for (const el of experiencesItemRefs.current) {
            el.style.backgroundPosition = `-${el.parentElement!.offsetLeft}px 0px`;
            el.style.backgroundSize = `${width}px ${height}px`;
        }
    }, [])

    useLayoutEffect(() => {
        onResize();

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    const word1Anim: AnimatedTextPropsPreset = isMobile ? {
        from: { rotateX: 90, opacity: 0 },
        to: { rotateX: 0, opacity: 1, duration: 4 },
    }
        : {
            split: AnimatedTextSplitBy.Letters,
            from: { rotateX: 90, opacity: 0 },
            to: { rotateX: 0, opacity: 1, ease: 'back.out(1.4)', duration: 2.5, stagger: { amount: .3, from: 'start' } },
        };

    const word2Anim: AnimatedTextPropsPreset = isMobile ? {
        from: { rotateX: 90, opacity: 0 },
        to: { rotateX: 0, opacity: 1, duration: 4, delay: .75 },
    }
        : {
            split: AnimatedTextSplitBy.Letters,
            from: { rotateX: 90, opacity: 0 },
            to: { rotateX: 0, opacity: 1, ease: 'back.out(1.4)', duration: 2.5, stagger: { amount: .3, from: 'end' }, delay: .75 },
        };

    const word3Anim: AnimatedTextPropsPreset = isMobile ? {
        from: { rotateX: 180, opacity: 0 },
        to: { rotateX: 0, opacity: 1, duration: 4, delay: 1.5 },
    }
        : {
            split: AnimatedTextSplitBy.Letters,
            from: { rotateX: 360, opacity: 0 },
            to: { rotateX: 0, opacity: 1, ease: 'back.out(1.4)', duration: 4, stagger: { amount: .5, from: 'center' }, delay: 1.5 },
            render: (content, index) => (
                <span ref={ref => experiencesItemRefs.current[index] = ref!} className="fw-700 text-gradient gradient-title-letter">{content}</span>
            )
        };

    return (
        <section ref={elRef} className='full-size fx fx-center'>
            {/* <WaveAnimation ref={waveAnimation} className="hp-landing-canvas" vertical={isSmall} /> */}
            <Waves ref={waveAnimation} className="absolute full-size hp-landing-canvas" isMobile={isSmall} />
            {/* <div className="hp-landing-canvasOverlay absolute full-size no-events" /> */}

            <div className='fx-column ai-center titles'>
                <AnimatedText
                    className="ff-title fs-9 ta-c px-text"
                    itemClassName="anim-l1 to-bottom inline-block"
                    {...word1Anim}
                >Framing</AnimatedText>
                <AnimatedText
                    className="ff-title fs-9 ta-c px-text"
                    itemClassName="anim-l2 to-bottom inline-block"
                    {...word2Anim}
                >Boundless</AnimatedText>
                <AnimatedText
                    className={
                        getClassNames('', [
                            !isMobile && 'ff-alt fs-10 ta-c px-text gradient-title text-gradient',
                            isMobile && 'ff-alt fs-10 ta-c px-text',
                        ])
                    }
                    itemClassName={
                        getClassNames('', [
                            !isMobile && 'anim-l3 gradient-title-item inline-block',
                            isMobile && 'inline-block text-gradient',
                        ])
                    }
                    {...word3Anim}
                >Experiences</AnimatedText>
            </div>

            <ActionButton className="hp-landing-connectAction" onClick={() => window.location.href = '#connect'}>Let’s Connect</ActionButton>
            <div className="hp-landing-scrollLine" />
        </section >
    )
}

export default LandingSection;

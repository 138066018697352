import { BREAKPOINTS } from "../specs";

export function RangeTransform(
    value: number,
    from: [number, number],
    to: [number, number],
): number {
    // Calculate the old range and the new range
    const oldRange = from[0] - from[1];
    const newRange = to[0] - to[1];

    // Convert the value from the old range to the new range
    const transformedValue = ((value - from[0]) * newRange) / oldRange + to[0];

    // Return the transformed value
    return transformedValue;
}

export function hexToRgb(hex: string): [number, number, number] {
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
}

export function ease(x: number): number {
    return x * x * x;
}
import { useRef } from "react";

const useCallbackRef = <T extends Function>(handler: T): T => {
    const callbackFn = useRef(handler);

    const fn = useRef((...args: any) => callbackFn.current?.(...args))

    callbackFn.current = handler;

    return fn.current as any as T;
}

export default useCallbackRef;
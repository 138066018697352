class ColorGradient {
    private colors: [number, number, number][];
    private numSegments: number;
  
    constructor(colors: [number, number, number][]) {
      this.colors = colors;
      this.numSegments = this.colors.length - 1;
    }
  
    get(x: number): [number, number, number] {
      // Ensure the input value x is within the range [0, 1]
      x = Math.max(0, Math.min(1, x));
  
      // Calculate the segment index and interpolation factor
      const segmentIndex = x * this.numSegments;
      const segmentIndexStart = Math.floor(segmentIndex);
      const t = segmentIndex - segmentIndexStart;
  
      // Determine the two color values to interpolate between
      const color1 = this.colors[segmentIndexStart];
      const color2 = this.colors[Math.min(segmentIndexStart + 1, this.numSegments)];
  
      // Perform linear interpolation for each RGBA component
      const interpolatedColor = color1.map((c1, idx) => Math.round(c1 + t * (color2[idx] - c1)));
  
      return interpolatedColor as [number, number, number];
    }
}

export default ColorGradient;
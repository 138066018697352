import { Sound, SoundsSet } from "./components/SoundPlayer";

export const LINKS = {
    site: 'https://www.aframe.studio/',
    email: 'mailto:connect@aframe.studio',
    dribble: 'https://dribbble.com/aframe-studio',
    instagram: 'https://www.instagram.com/aframe.studio/',
    linkedIn: 'https://www.linkedin.com/company/aframe-studio',
}

export const BREAKPOINTS = {
    xl: 1400,
    lg: 1024,
    md: 768,
    sm: 425,
    nav: 736,
}

const isBreakpoint = (point: number) => window.innerWidth <= point;

const isMobileBrowser = () => /Android|iPhone/i.test(navigator.userAgent);

export const testIsSmallBreakpoint = () => window.innerWidth <= BREAKPOINTS.sm;

export const testIsNavBreakpoint = () => window.innerWidth <= BREAKPOINTS.nav;

export const testIsMobile = () => isBreakpoint(BREAKPOINTS.sm) && isMobileBrowser();

export const ANIM_SPECS = {
    ease: 'power2.out',
    scrup: testIsMobile() ? true : 2,
}

export const AUDIOS = [
    "/assets/ambient.wav",
]

export const SOUNDS: (Sound | SoundsSet)[] = [
    {
        srcs: [
            '/assets/sounds/hover1.mp3',
            '/assets/sounds/hover2.mp3',
            '/assets/sounds/hover3.mp3',
        ], key: 'hover',
        volume: .75,
    },
    {
        srcs: [
            '/assets/sounds/click1.mp3',
            '/assets/sounds/click2.mp3',
        ], key: 'click',
        volume: .75,
    },
    {
        srcs: [
            '/assets/sounds/A3.mp3',
            '/assets/sounds/B3.mp3',
            '/assets/sounds/C3.mp3',
            '/assets/sounds/D3.mp3',
            '/assets/sounds/E3.mp3',
            '/assets/sounds/F3.mp3',
            '/assets/sounds/G3.mp3',
        ], key: 'key3',
        volume: .08,
    },
    {
        srcs: [
            '/assets/sounds/A4.mp3',
            '/assets/sounds/B4.mp3',
            '/assets/sounds/C4.mp3',
            '/assets/sounds/D4.mp3',
            '/assets/sounds/E4.mp3',
            '/assets/sounds/F4.mp3',
            '/assets/sounds/G4.mp3',
        ], key: 'key4',
        volume: .08,
    },
    {
        srcs: [
            '/assets/sounds/A5.mp3',
            '/assets/sounds/B5.mp3',
            '/assets/sounds/C5.mp3',
            '/assets/sounds/D5.mp3',
            '/assets/sounds/E5.mp3',
            '/assets/sounds/F5.mp3',
            '/assets/sounds/G5.mp3',
        ], key: 'key5',
        volume: .08,
    },
]


const throttleFps = (callback: (delta: number) => any, frameInterval: number) => {
    let lastTimestamp = 0;

    const throttledCallback = function () {
        const timestamp = performance.now();
        const deltaTime = timestamp - lastTimestamp;

        if (deltaTime < frameInterval) return;
        lastTimestamp = timestamp;

        const delta = deltaTime / frameInterval;
        callback(delta);
    };

    return throttledCallback;
}

export default throttleFps;
import { gsap } from "gsap";
import { MouseEvent, useEffect, useRef } from "react";
import { PropsWithClassName, getClassNameFromProps } from "../utilities/props";
import SoundPlayer, { playSoundWithTimeout } from "./SoundPlayer";

interface ActionButtonProps extends PropsWithClassName {
    onClick?: (event: MouseEvent) => void;
    children: string;
    type?: 'button' | 'submit' | 'reset';
}

const ActionButton = (props: ActionButtonProps) => {
    const elRef = useRef<HTMLButtonElement>(null);
    const hoverElRef = useRef<HTMLDivElement>(null);
    const hoverAnim = useRef<gsap.core.Tween>();

    const soundEvent = playSoundWithTimeout('hover');

    useEffect(() => {
        gsap.context(() => {
            const clipObj = { value: 0 };
            hoverAnim.current = gsap.to(clipObj, {
                value: 75, paused: true, duration: .75, ease: 'power2.in',
                onUpdate: () => {
                    if (!hoverElRef.current) return;
                    hoverElRef.current.style.clipPath = `circle(${(clipObj.value).toFixed(2)}% at 50% 100%)`;
                }
            });
        }, elRef.current!);
    }, []);

    return <button ref={elRef}
        className={getClassNameFromProps('abt', props)}
        type={props.type ?? 'button'}
        onClick={(event) => {
            props.onClick?.(event);
            SoundPlayer.instance.play('click');
        }}
        onMouseEnter={() => {
            soundEvent.onMouseEnter();
            hoverAnim.current?.play();
        }}
        onMouseLeave={() => {
            soundEvent.onMouseLeave();
            hoverAnim.current?.reverse();
        }}
    >
        <div className="abt-content" >{props.children}</div>
        <div ref={hoverElRef} className="abt-hover" style={{ clipPath: 'circle(0%)' }}>
            <div className="abt-content" >{props.children}</div>
        </div>
    </button>
}

export default ActionButton;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ANIM_SPECS } from './specs';

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: ANIM_SPECS.ease, duration: 1 })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
